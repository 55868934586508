<template>
	<div class="min-h-screen bg-black flex flex-col justify-center py-12 sm:px-6 lg:px-8">
		<div class="sm:mx-auto sm:w-full sm:max-w-md">
			<img alt="Conduction PTY LTD" class="h-32 w-auto mx-auto mb-12 mt-" src="/logo.svg" />
			<h2 class="mt-6 text-center text-3xl font-extrabold text-primary-600">Help! I forgot my password!</h2>
		</div>

		<div v-if="message" class="mt-6 relative text-center text-red-500">
			<span>{{ message }}</span>
		</div>

		<div v-if="successMessage" class="mt-6 relative text-center text-green-500">
			<span>{{ successMessage }}</span>
		</div>

		<div class="mt-2 sm:mx-auto sm:w-full sm:max-w-md">
			<div class="bg-black py-8 px-4 shadow sm:rounded-lg sm:px-10">
				<form class="space-y-6" @submit.prevent="forgot" @keyup.enter.prevent="forgot">
					<div>
						<label class="block text-sm font-medium text-white" for="email"> Email address </label>
						<div class="mt-1">
							<input
								id="email"
								v-model="email"
								autocomplete="email"
								class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
								name="email"
								required
								type="email"
							/>
						</div>
					</div>

					<div>
						<button
							class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
							type="submit"
						>
							Request password reset
						</button>
					</div>
				</form>
			</div>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { ref } from 'vue';
	import { useAuthStore } from '@modules/auth/store';
	import { useRouter } from 'vue-router';

	const authStore = useAuthStore();
	const router = useRouter();

	//Send to dashboard if they are already authenticated
	if (authStore.isAuthenticated) {
		router.push({
			name: 'dashboard',
		});
	}

	const email = ref('');
	const message = ref('');
	const successMessage = ref('');

	const forgot = () => {
		message.value = '';
		successMessage.value = '';
		authStore
			.forgotPassword(email.value)
			.then((message) => {
				successMessage.value = message;
			})
			.catch((error) => {
				message.value = error.message;
			});
	};
</script>
